import React from "react"

import { Link } from "@material-ui/core"

import { ViewProps } from "./ga-opt-out-link-types"

const GaOptOutLinkView = ({ label, message, showAlert }: ViewProps) => {
  const handleClick = () => {
    showAlert(`Gelukt!`, message)
  }

  return (
    <Link
      href="javascript:gaOptout();"
      underline="always"
      onClick={handleClick}
    >
      {label}
    </Link>
  )
}

export default GaOptOutLinkView
