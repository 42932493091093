import React from "react"

import { Container, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ChevronRight } from "@material-ui/icons"

import { Link as GatsbyLink } from "gatsby"
import ConditionalWrap from "conditional-wrap"
import clsx from "clsx"

import { Crumb, ViewProps } from "./main-shell-types"

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    display: `flex`,
    alignItems: `center`,
    color: theme.palette.grey[700],
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up(`sm`)]: {
      padding: theme.spacing(3, 0),
    },
  },

  icon: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}))

const MainShellView = ({
  children,
  header,
  breadcrumbs,
  wrapWithContainer = true,
  addPadding = true,
}: ViewProps) => {
  const classes = useStyles()

  const renderCrumb = (crumb: Crumb) =>
    crumb.link ? (
      <Link component={GatsbyLink} to={crumb.link}>
        {crumb.label}
      </Link>
    ) : (
      crumb.label
    )

  return (
    <>
      {header}

      {breadcrumbs && (
        <Container>
          <Typography
            variant="body2"
            component="div"
            className={classes.breadcrumbs}
          >
            {renderCrumb({ label: `Todocards.nl`, link: `/` })}

            {breadcrumbs.map((crumb, i) => (
              <React.Fragment key={i}>
                <ChevronRight fontSize="inherit" className={classes.icon} />
                {renderCrumb(crumb)}
              </React.Fragment>
            ))}
          </Typography>
        </Container>
      )}

      <ConditionalWrap
        condition={wrapWithContainer}
        wrap={children => <Container>{children}</Container>}
      >
        <div
          className={clsx({
            blockPaddingTop: addPadding && !breadcrumbs,
            blockPaddingBottom: addPadding,
          })}
        >
          {children}
        </div>
      </ConditionalWrap>
    </>
  )
}

export default MainShellView
