import React from "react"

import { Box, Link, Typography } from "@material-ui/core"
import { Link as GatsbyLink } from "gatsby"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"
import GaOptOutLink from "../components/utils/ga-opt-out-link"

const TermsPage = () => {
  return (
    <MainShell breadcrumbs={[{ label: `Privacyverklaring` }]}>
      <Helmet>
        <title>Privacyverklaring Todocards.nl</title>

        <meta
          name="description"
          content="Jouw privacy is erg belangrijk voor ons. In deze privacyverklaring informeren we jou over ons beleid, wat we met jouw gegevens doen en waarom we deze nodig hebben."
        />
      </Helmet>

      <Typography variant="h1">Privacyverklaring Todocards.nl</Typography>

      <Typography variant="subtitle2" paragraph={true}>
        Datum: 19 maart 2020
        <br />
        Versie: 1.0
        <br />
        <Link
          href="/privacyverklaring_todocards_nl_versie_1_0.pdf"
          target="_blank"
          underline="always"
        >
          Download in PDF-formaat
        </Link>
      </Typography>

      <Typography paragraph={true}>
        Jouw privacy is erg belangrijk voor ons. In deze privacyverklaring
        informeren we jou over ons beleid, wat we met jouw gegevens doen en
        waarom we deze nodig hebben. Samengevat komt het neer op de volgende
        twee punten:
      </Typography>

      <Box ml={2}>
        <Typography gutterBottom>
          - We gebruiken jouw gegevens alleen voor het uitvoeren van onze
          dienstverlening en/of het verbeteren daarvan.
        </Typography>

        <Typography paragraph={true}>
          - Jouw gegevens worden nooit voor commerciële doelstellingen ter
          beschikking gesteld aan derden.
        </Typography>
      </Box>

      <Typography paragraph={true}>
        Deze privacyverklaring is van toepassing op alle gegevens die jij ons
        verstrekt via Todocards.nl of info@todocards.nl.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Verantwoordelijke
      </Typography>

      <Typography paragraph={true}>
        Jouw gegevens worden verwerkt door:
      </Typography>

      <Typography paragraph={true}>
        Todocards.nl
        <br />
        Mr. Tripkade 18
        <br />
        3571 SX Utrecht
        <br />
        Nederland
      </Typography>

      <Typography paragraph={true}>
        E-mailadres: info@todocards.nl
        <br />
        KvK-nummer: 30221205
        <br />
        Btw-identificatienummer: NL001179039B37
      </Typography>

      <Typography paragraph={true}>
        Todocards.nl is een handelsnaam van TD Media.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Grondslagen
      </Typography>

      <Typography paragraph={true}>
        Todocards.nl beroept zich op de volgende juridische grondslagen om jouw
        gegevens te kunnen verwerken:
      </Typography>

      <Typography variant="h5" component="h3" gutterBottom>
        Uitvoering van de overeenkomst
      </Typography>

      <Typography paragraph={true}>
        Om de contractuele afspraken tussen jou en Todocards.nl na te kunnen
        komen hebben wij gegevens nodig. Dit is het geval bij:
      </Typography>

      <Box ml={2}>
        <Typography gutterBottom>- Plaatsen van een bestelling</Typography>
        <Typography gutterBottom>- Contact met de klantenservice</Typography>
        <Typography paragraph={true}>- Retourneren</Typography>
      </Box>

      <Typography variant="h5" component="h3" gutterBottom>
        Wettelijke verplichting
      </Typography>

      <Typography paragraph={true}>
        Wanneer je een bestelling hebt gedaan sturen wij jou een factuur. Wij
        zijn wettelijk verplicht om deze factuur te bewaren.
      </Typography>

      <Typography variant="h5" component="h3" gutterBottom>
        Gerechtvaardigd belang
      </Typography>

      <Typography paragraph={true}>
        De gegevens die jij ons verstuurt via het contactformulier of
        info@todocards.nl hebben wij nodig om jou van een passende reactie te
        kunnen voorzien.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Toegang
      </Typography>

      <Typography paragraph={true}>
        Wij verkopen jouw gegevens nooit aan derden. Wij geven jouw gegevens
        alleen door aan andere partijen als dat echt nodig is. Het betreft dan
        de volgende partijen:
      </Typography>

      <Box ml={2}>
        <Typography gutterBottom>- Bezorgdiensten</Typography>
        <Typography gutterBottom>- Betalingsverwerkers</Typography>
        <Typography paragraph={true}>- IT-dienstverleners</Typography>
      </Box>

      <Typography paragraph={true}>
        De partijen die van ons toegang krijgen tot jouw gegevens, mogen deze
        alleen gebruiken om jou een dienst te leveren namens Todocards.nl.
        Tenzij ze zelf verantwoordelijk zijn voor het verkrijgen en beschermen
        van jouw gegevens.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Cookies
      </Typography>

      <Typography paragraph={true}>
        Wij gebruiken zogeheten functionele cookies om te zorgen dat de website
        goed werkt. Hiermee kunnen wij bijvoorbeeld bijhouden welke producten er
        in jouw winkelwagen zitten. Daarnaast plaatsen wij de volgende cookies:
      </Typography>

      <Typography variant="h5" component="h3" gutterBottom>
        Google Analytics
      </Typography>

      <Typography paragraph={true}>
        Wij gebruiken deze dienst om bij te houden en rapportages te krijgen
        over hoe bezoekers de website gebruiken. Wij hebben een
        verwerkersovereenkomst gesloten met Google, de gegevens worden anoniem
        verwerkt en wij hebben Google niet toegestaan de verkregen informatie te
        gebruiken voor andere Google diensten.
      </Typography>

      <Typography paragraph={true}>
        Heb je liever niet dat jouw gegevens naar Google Analytics worden
        verstuurd? Dan kan je{" "}
        <GaOptOutLink
          label="hier"
          message="Jouw gegevens zullen in het vervolg niet meer naar Google Analytics worden gestuurd."
        />{" "}
        klikken om gebruikt te maken van de ‘opt-out’ regeling. Er wordt dan een
        cookie op jouw computer geplaatst. Op basis van deze cookie kunnen wij
        in het vervolg zien dat je gebruik hebt gemaakt van deze regeling en
        zullen we jouw gegevens niet doorsturen.
      </Typography>

      <Typography paragraph={true}>
        Bovenstaande regeling geldt alleen voor Todocards.nl. Google biedt ook
        de mogelijkheid om ‘opt-out’ in te stellen voor alle websites die je
        bezoekt. Klik{" "}
        <Link
          href="https://tools.google.com/dlpage/gaoptout?hl=nl"
          target="_blank"
          rel="noopener noreferrer nofollow"
          underline="always"
        >
          hier
        </Link>{" "}
        voor meer informatie.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Opslag
      </Typography>

      <Typography paragraph={true}>
        Jouw gegevens worden bijna altijd binnen de Europese Unie opgeslagen. In
        het uitzonderlijke geval dat klantgegevens worden doorgegeven naar
        landen buiten de Europese Unie, zorgen we ervoor dat jouw privacy op een
        passende manier beschermd blijft. Voor een aantal landen is dit
        officieel vastgesteld. Sommige Amerikaanse partijen hebben zich
        geregistreerd onder het Privacy Shield dat voldoende bescherming biedt.
        Of we werken met officiële modelcontracten die speciaal zijn opgesteld
        om jouw privacy te waarborgen.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Bewaartermijnen
      </Typography>

      <Typography paragraph={true}>
        Wij zijn verplicht facturen 7 jaar te bewaren. Op een factuur staat jouw
        naam, factuuradres, datum en welke producten jij besteld hebt. Voor alle
        overige gegevens hanteren wij een bewaartermijn van 3 jaar. Na het
        verlopen van een bewaartermijn zullen de gegevens geanonimiseerd of
        verwijderd worden.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Jouw rechten
      </Typography>

      <Typography paragraph={true}>
        Op grond van de geldende Nederlandse en Europese wetgeving heb jij als
        betrokkene bepaalde rechten met betrekking tot de persoonsgegevens die
        door of namens ons worden verwerkt. Dit betekent dat je ons kan
        verzoeken om jou een kopie te sturen van alle gegevens die we van jou
        hebben. Daarnaast kan je ons vragen om bepaalde gegevens te wijzigen of
        verwijderen.
      </Typography>

      <Typography paragraph={true}>
        In beginsel sturen wij, om misbruik te voorkomen, kopieën van jouw
        gegevens enkel naar jouw bij ons reeds bekende e-mailadres. In het geval
        dat je de gegevens op een ander e-mailadres of bijvoorbeeld per post
        wenst te ontvangen, zullen wij jou vragen je te legitimeren.
      </Typography>

      <Typography paragraph={true}>
        Je hebt te allen tijde het recht om een klacht in te dienen bij de
        Autoriteit Persoonsgegevens als je vermoedt dat wij jouw
        persoonsgegevens op een verkeerde manier gebruiken.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Vragen of klachten
      </Typography>

      <Typography paragraph={true}>
        Bij vragen of klachten omtrent ons privacy beleid kan je contact met ons
        opnemen door een e-mail te sturen naar info@todocards.nl, via het{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contactformulier
        </Link>{" "}
        of door een brief te sturen naar:
      </Typography>

      <Typography>
        Todocards.nl
        <br />
        Mr. Tripkade 18
        <br />
        3571 SX Utrecht
        <br />
        Nederland
      </Typography>
    </MainShell>
  )
}

export default TermsPage
